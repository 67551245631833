import ActiveClockIcon from 'public/assets/icons/active-clock.svg';
import AdultIcon from 'public/assets/icons/adult.svg';
import ArrowDownIcon from 'public/assets/icons/arrow-down.svg';
import ArrowLeftIcon from 'public/assets/icons/arrow-left.svg';
import BabyIcon from 'public/assets/icons/baby.svg';
import CalendarIcon from 'public/assets/icons/calendar.svg';
import CallCallingIcon from 'public/assets/icons/call-calling.svg';
import CallIcon from 'public/assets/icons/call.svg';
import ChevronLeftIcon from 'public/assets/icons/chevron-left.svg';
import ChildIcon from 'public/assets/icons/child.svg';
import ClockIcon from 'public/assets/icons/clock.svg';
import DirectUpIcon from 'public/assets/icons/direct-up.svg';
import DepartureIcon from 'public/assets/icons/direction.svg';
import EditIcon from 'public/assets/icons/edit.svg';
import HomeIcon from 'public/assets/icons/home.svg';
import SmallKakaotalkIcon from 'public/assets/icons/kakaotalk.svg';
import LocationIcon from 'public/assets/icons/location.svg';
import LockIcon from 'public/assets/icons/lock.svg';
import LoginIcon from 'public/assets/icons/login.svg';
import LogoutIcon from 'public/assets/icons/logout.svg';
import MapIcon from 'public/assets/icons/map.svg';
import NotificationIcon from 'public/assets/icons/notification.svg';
import PercentageCircleIcon from 'public/assets/icons/percentage-circle.svg';
import Profile2UserIcon from 'public/assets/icons/profile-2user.svg';
import ProfileIcon from 'public/assets/icons/profile.svg';
import SmallWhatsappIcon from 'public/assets/icons/small-whatsapp.svg';
import TicketDiscountIcon from 'public/assets/icons/ticket-discount.svg';
import UserSquareIcon from 'public/assets/icons/user-square.svg';
import UserIcon from 'public/assets/icons/user.svg';
import ZaloIcon from 'public/assets/icons/zalo.svg';

export {
  ActiveClockIcon,
  AdultIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  BabyIcon,
  CalendarIcon,
  CallCallingIcon,
  CallIcon,
  ChevronLeftIcon,
  ChildIcon,
  ClockIcon,
  DepartureIcon,
  DirectUpIcon,
  EditIcon,
  HomeIcon,
  LocationIcon,
  LockIcon,
  LoginIcon,
  LogoutIcon,
  MapIcon,
  NotificationIcon,
  PercentageCircleIcon,
  Profile2UserIcon,
  ProfileIcon,
  SmallKakaotalkIcon,
  SmallWhatsappIcon,
  TicketDiscountIcon,
  UserIcon,
  UserSquareIcon,
  ZaloIcon,
};
