import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import moment from 'moment';

export const SOCKET = io(process.env.NEXT_PUBLIC_SOCKET_BASE_URL || '/', {
  reconnectionDelay: 5000,
});

interface Event {
  name: string;
  handler(...args: any[]): any;
}

function useWebSocket() {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    SOCKET.on('connect', () => {
      if (SOCKET.connected) {
        console.log(`Connected websocket at ${moment().toString()}`);
      }
    });

    SOCKET.on('connect_error', error => {
      console.log(error);
      SOCKET.close();
    });

    return () => {
      SOCKET.off('connect');
      SOCKET.off('connect_error');
    };
  }, []);

  useEffect(() => {
    if (events.length > 0) {
      for (const event of events) {
        SOCKET.on(event.name, event.handler);
      }

      return function () {
        for (const event of events) {
          SOCKET.off(event.name);
        }
      };
    }
  }, [events]);

  return { setEvents };
}

export default useWebSocket;
